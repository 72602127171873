import './InstagramFeed.css';
import TextBlock from "../TextBlock/TextBlock";
import {useEffect, useRef, useState} from "react";

const InstagramFeed = () => {
    const [iframeAvailable, setIframeAvailable] = useState(true);

    const iframe = useRef(null);

    const AutoHeight = () => {
        if (!iframe.current.contentWindow.document.body.innerHTML.trim().length) {
            setIframeAvailable(false);
        }
        else {
            setTimeout(() => {
                iframe.current.style.height = iframe.current.contentWindow.document.body.querySelector('iframe').scrollHeight + 'px';
            }, 2000);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => AutoHeight());
    }, []);

    return (
        <div id={"novedades"} className={"instagram-feed"}>
            <TextBlock
                title={
                    <>
                        ¡Síguenos en <a className={"ig"} href={"https://www.instagram.com/snacksunbar/"}
                                        target={"_blank"}
                                        rel={"noreferrer"}>Instagram</a> y <a className={"fb"}
                                                                              href="https://www.facebook.com/profile.php?id=61555486260674"
                                                                              target={"_blank"}
                                                                              rel={"noreferrer"}>Facebook</a> para estar
                        al
                        día de todos nuestros eventos!
                    </>
                }
                content={
                    iframeAvailable &&
                    <iframe ref={iframe} title={"Novedades de Instagram"} src={"igfeed.php"} width="100%"
                            onLoad={AutoHeight}></iframe>
                }
            />
        </div>

    )
};

export default InstagramFeed;